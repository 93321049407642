import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
const routes: Routes = [
    {
        path      : '',
        component : HomeComponent
    },
    {
        path: 'appointment',
        loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
    },

    {
        path: 'page',
        loadChildren: () => import('./page/page.module').then(m => m.PageModule)
    },

    {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
    },

    {
        path : 'awareness',
        loadChildren : () => import('./awareness/awareness.module').then(m => m.AwarenessModule)
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
