import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomService } from '../_service/custom.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    submitform : boolean = false;
    show_successmsg : boolean = false;
	show_errormsg : boolean = false;
    newsletterForm:FormGroup;
	msg: any;

    private isConsented: boolean = false;

    constructor(
        private fb : FormBuilder,
        public api : CustomService,

    ) {
        //this.deleteCookie('ACCEPT');
        this.newsletterForm = this.fb.group({
            email : ['',[Validators.required,Validators.email]],

        });

        this.isConsented = this.getCookie('ACCEPT') === '1';
    }

    ngOnInit() {
    }

    get f(){
        return this.newsletterForm.controls;
    }

    Onsubmit(){
        this.submitform = true;
        if(this.newsletterForm.invalid){
            return false;
        }
        this.api.newsletter(this.newsletterForm.value).subscribe(
            (data:any)=>{
                this.show_successmsg = true;
                this.show_errormsg = false;
            },(errors:any)=>{
				//console.log(errors.error.msg);
                this.show_successmsg = false;
                this.show_errormsg = true;
                this.msg = errors.error.msg;
            }
        );
    }

    private getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    private deleteCookie(name) {
        this.setCookie(name, '', -1);
    }

    private setCookie(name: string, value: string, expireDays: number, path: string = '') {
        let d:Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    private consent(isConsent: boolean, e: any) {
        if (!isConsent) {
            return this.isConsented;
        } else if (isConsent) {
            this.setCookie('ACCEPT', '1',10);
            this.isConsented = true;
            e.preventDefault();
        }
    }

    setcokies(){
        this.setCookie('ACCEPT', '1',10);
        this.isConsented = true;
    }

}
