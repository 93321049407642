import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomService {
	
    API = environment.api;
	//otp = environment.otp;
    headers = new HttpHeaders();

    constructor(
        private http: HttpClient,
    ) {
        this.headers.append('Content-Type', 'text/plain');
        this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        this.headers.append('Access-Control-Allow-Headers', "Origin, X-Requested-With, Content-Type, Accept, x-auth");
    }
	sendOtp(data){
		console.log(environment)
		//console.log(this.otp+'sendOtp');
		//return false;
        return this.http.post(this.API+'sendotp',JSON.stringify(data),{headers:this.headers});
		//return this.http.post(this.API+'sendOtp',{email});
	}
	checkOtp(data){
		console.log(environment)
		//console.log(otpVal);
		//return false;
        return this.http.post(this.API+'checkotp',JSON.stringify(data),{headers:this.headers});
		//return this.http.post(this.otp+'checkotp',{email,otpVal});
	}
    contactus(data){
        return this.http.post(this.API+'contactus',JSON.stringify(data),{headers:this.headers});
    }

    getawarenessvideo(){
        return this.http.get(this.API+'getallawarenessvideoes',{headers:this.headers});
    }

    getawarenesspost(){
        return this.http.get(this.API+'getallawarenessposts',{headers:this.headers});
    }

    getallgalleryimages(){
        return this.http.get(this.API+'getallimages',{headers:this.headers});
    }

    getallcyberngoimages(){
        return this.http.get(this.API+'getallcyberngoimages',{headers:this.headers});
    }

    getgalleryvideo(){
        return this.http.get(this.API+'getallgalleryvideoes',{headers:this.headers});
    }

    getallblogs(){
        return this.http.get(this.API+'getallblogs',{headers:this.headers});
    }

    blogdetail(blogid){
        return this.http.get(this.API+'blogdetail/'+blogid,{headers:this.headers});
    }

    uploadfile(fileToUpload: File){
        let headers = new HttpHeaders();
        const formData: FormData = new FormData();
        formData.append('user_upload_file', fileToUpload, fileToUpload.name);
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.post<any>(this.API+'uploadfile', formData, { headers: headers });
    }

    uploadfileResume(fileToUpload: File){
        let headers = new HttpHeaders();
        const formData: FormData = new FormData();
        formData.append('user_upload_file', fileToUpload, fileToUpload.name);
        headers.append('Access-Control-Allow-Origin', '*');
        return this.http.post<any>(this.API+'uploadfileresume', formData, { headers: headers });
    }

    newinternship(data){
        return this.http.post(this.API+'savenewintenship',JSON.stringify(data),{headers:this.headers});
    }

    joinus(data){
        return this.http.post(this.API+'joinus',JSON.stringify(data),{headers:this.headers});
    }

    newsletter(data){
        return this.http.post(this.API+'savenewsletter',JSON.stringify(data),{headers:this.headers});
    }
}
