import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { OwlModule } from 'ngx-owl-carousel';
import { CustomService } from './_service/custom.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlayVideoComponent } from './play-video/play-video.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        PlayVideoComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        OwlModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule
    ],
    providers: [
        CustomService
    ],
    entryComponents:[
        PlayVideoComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
