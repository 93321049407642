import { Component, OnInit } from '@angular/core';
import { CustomService } from '../_service/custom.service';
import { environment } from '../../environments/environment';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    env : any;
    available_blogs : boolean = false;
    all_blogs : any;

    testimonial_config = {
        items: 4,
        dots: false,
        nav: false,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:1,
                nav:false
            },
            1000:{
                items:4,
                nav:true,
                loop:false
            }
        }
    }
    constructor(
        public api : CustomService
    ) {
        this.env = environment;
    }

    ngOnInit() {

        this.api.getallblogs().subscribe(
            (data:any)=>{
                if(data.blogs.results.length > 0){
                    this.all_blogs = data.blogs.results;
                    this.available_blogs = true;
                }else{
                    this.available_blogs = false;
                }

            },(errors:any)=>{
                console.log(errors.error);
            }
        );

    }

}
