import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    show_mobile_menu : boolean = false;
    constructor() { }

    ngOnInit() {
    }

    show_submenu(){
        if(this.show_mobile_menu){
            this.show_mobile_menu = false;
        }else{
            this.show_mobile_menu = true;
        }
    }

    mobile_open(liID){
        var li_object = document.getElementById("my_"+liID);
        var atag_object = document.getElementById("atag_"+liID);
        var subul_object = document.getElementById("submenu_"+liID);
        if(li_object.classList.contains('hover')){
            li_object.classList.remove("hover");
            atag_object.classList.remove("active");
            subul_object.classList.remove("active");
        }else{
            li_object.classList.add("hover");
            atag_object.classList.add("active");
            subul_object.classList.add("active");

        }

    }



}
