import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
    VideoCode : any;
}

@Component({
    selector: 'app-play-video',
    templateUrl: './play-video.component.html',
    styleUrls: ['./play-video.component.css']
})
export class PlayVideoComponent implements OnInit {

    availableData : boolean = false;
    iframecode :any;
    youtubecode : any;
    show_video : boolean = false;
    constructor(
        public dialogRef: MatDialogRef<PlayVideoComponent>,
        public sanitizer : DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.youtubecode = data.VideoCode;

        //this.iframecode = '<iframe width="1280" height="720" src="'+this.youtubecode+'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        this.iframecode = '<iframe class="embed-responsive-item" src="'+this.youtubecode+'" allowfullscreen></iframe>';

    }

    ngOnInit() {


    }
    URLsanitizer(){
        return this.sanitizer.bypassSecurityTrustHtml(this.iframecode);
    }

    closepopup(){
        this.dialogRef.close();
    }

}
