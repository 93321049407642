// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//let API = "http://localhost/cyberngo/admin/";
//let API = "https://vmp.forenzy.net/cyberngo/admin/";
let API = "https://cyberngo.org/admin/";
export const environment = {
    //production: false,
    //otp : API + 'api/volunteer/',
    api : API + 'api/gateway/',
    awareness_image : API + 'uploads/awarenessimage/',
    blog_image : API + 'uploads/blog_image/',
    gallery_image : API + 'uploads/galleryimage/',
    gallery_upload_image : API + 'uploads/galleryuploadimage/',
    life_at_cyberngo_image : API + 'uploads/lifeatcyberngo/',
	production: true
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
